.reel {
  margin: 5px 10px;
  width: 50px;
}
.reel-arrows {
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-size: 42px;
}
.reel-item {
  display: flex;
  justify-content: center;
}
.reel-input {
  display: flex;
  justify-content: center;
}
.reel-input input[type=number] {
  width: 50px;
  text-align: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  color: white;
}