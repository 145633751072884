.ff001-container {
  display: block;
  background-color: rgb(27, 27, 27);
  padding: 16px;
  border-radius: 12px;
}
.spin {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  border-radius: 8px;
  padding: 8px;
  background: rgb(36,36,36);
  background: linear-gradient(180deg, rgba(36,36,36,1) 0%, rgba(0,110,255,1) 100%);
}
.spin-index-num {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 8px;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.4);
  pointer-events: none;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spin-freespins {
  background: rgb(36,36,36);
  background: linear-gradient(180deg, rgba(36,36,36,1) 0%, rgb(109, 6, 137) 100%);

}
.reelContainer {
  display: flex;
}
.scenario-presets {
  margin-bottom: 32px;
}
.model-buttons {
  display: flex;
  margin: 24px 0;
}
.btn-freespins-group {
  display: flex;
}
.btn-save, .btn-freespins-action {
  cursor: pointer;
  padding: 8px;
  font-weight: 800;
  background-color: aquamarine;
  color: black;
  margin-right: 8px;
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
}
.btn-freespins-action {
  font-size: 12px;
  max-width: 125px;
}
.btn-play {
  align-self: flex-end;
  background: linear-gradient(180deg, rgb(239, 223, 143) 0%, rgb(255, 170, 0) 100%);
  border: 3px solid #ffed7a;
}
.ff001-sep {
  margin: 8px 0;
}
.ff001-wild-multiplier, .ff001-powerup, .ff001-userid {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ff001-scenario-select select {
  width: 100%;
  font-size: 22px;
}