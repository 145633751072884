.coin-spins-window {
  position: relative;
  display: flex;
}
.coin-spins .sep {
  margin: 8px 0;
  border-bottom: 15px solid #402d41;
}
.coin-spins-reel {
  width: 73px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.coin-spins-item {
  width: 100%;
  height: 30px;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #333333;
}
.coin-spins-btns {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* select */
.coin-spins-item select::after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  background-color: var(--select-arrow);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  grid-area: select;
  justify-self: end;
}
.coin-spins-item select {
  text-align: center;
  background-color: rgb(164, 160, 147);
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  height:100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;

  width: 100%;
  /* min-width: 15ch; */
  /* max-width: 30ch; */
  border: 1px solid var(--select-border);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 12px;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  display: grid;
  grid-template-areas: "select";
  grid-area: select;
}
.coin-spins-item select[disabled] {
  cursor: default;
  color: rgba(0, 0, 0, 0.5);
  background-color: #eee;
  background-image: linear-gradient(to top, #6d6d6d, #727272 33%);
}
.btn-cs-action {
  cursor: pointer;
  padding: 8px;
  font-weight: 800;
  background-color: #87148d;
  color: #fff;
  font-size: 12px;
  max-width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}